<template>
  <div>
    <h1 v-t="'error.500.title'"/>
    <p>
      <i18n path="error.500.text">
        <template #page>
          <router-link
            :to="desiredRoute"
            v-text="$t (desiredRoute.titleKey)"/>
        </template>
      </i18n>
    </p>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'Error500',

    computed: {
      ...mapState ({
        desiredRoute: state => state.router.desiredRoute
      })
    }
  }
</script>
